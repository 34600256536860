<template>
  <div class="disease" >
    <div v-if="$route.query.type" >
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item><img src="../../../src/assets/images/banner01.png"></van-swipe-item>
      </van-swipe>
      <div>
        <div class="disease-top">
          <div class="title">
            <div>乳腺自查</div>
          </div>
          <div class="list-box">
            育龄期妇女应学会自查乳腺。（1）时间：每次月经后5~10天为宜。（2）体位：首先端坐、两臂自然下垂，乳房肥大下垂明显者，可平卧，肩下垫小枕，使胸部隆起。左手检查右侧乳房，右手检查左侧乳房，不能同时检查两侧。若只有一侧有肿块，先查健侧再查患侧。
            检查顺序：以乳头为中心，把乳房分成四个象限。应从乳房的外上(包括腋窝部)、外下、内下、内上各象限及中央区依次全面检查。
            注意事项：应用手指掌面而不是指尖，不要用手指捏乳房组织，否则会将捏到的乳腺组织误认为是肿块。
          </div>
        </div>
        <div class="tab-box" style="margin-bottom: .5rem">
          <div class="tab-title">
            <div v-for="(item,index) in symptoms" :class="{active:tubercleShow==index}" @click="getTubercle(index,item)">{{item}}</div>
<!--            <div @click="onTubercle('0')" :class="{active:tubercleShow=='0'}">乳腺结节</div>-->
<!--            <div @click="onTubercle('1')" :class="{active:tubercleShow=='1'}">乳腺增生</div>-->
<!--            <div @click="onTubercle('2')" :class="{active:tubercleShow=='2'}">乳腺囊肿</div>-->
<!--            <div @click="onTubercle('3')" :class="{active:tubercleShow=='3'}">乳腺纤维腺瘤</div>-->
          </div>
          <div class="box" id="summary">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="jibingmiaoshu"></svg-icon> 疾病概述</div>
              <div class="list">
                  {{tubercleData[0].summary}}
              </div>
            </div>
          </div>
          <div class="box" id="diagnosis">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="jianchaxiangmu"></svg-icon> 诊断方法</div>
              <div class="list">
                {{tubercleData[0].diagnosis}}
              </div>
            </div>
          </div>
          <div class="box" id="followup">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="suifangzhiliao"></svg-icon> 随访观察</div>
              <div class="list">
                {{tubercleData[0].followup}}
              </div>
            </div>
          </div>
          <div class="box" id="prevention">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="jibingyufang1"></svg-icon> 疾病预防</div>
              <div class="list">
                {{tubercleData[0].prevention}}
              </div>
            </div>
          </div>
          <div class="box" id="treatment">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="yaowuzhiliao"></svg-icon> 治疗方法</div>
              <div class="list">
                {{tubercleData[0].treatment}}
              </div>
            </div>
          </div>
          <div class="box" id="risk">
            <div  style="margin-top: .3rem">
              <div class="list-top"><svg-icon icon-class="jianchaxiangmu"></svg-icon> 癌变风险</div>
              <div class="list">
                {{tubercleData[0].risk}}
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 1.5rem;"></div>
          <div class="buliangfanying">
            <div  v-for="item in jiejieList" :class="{active : isActive == item.code}" @click="geclickNumt(item.code)">
              <p v-if="isActive !== item.code"><svg-icon :icon-class="item.icon"></svg-icon></p>
              <p v-else><svg-icon :icon-class="item.iconActive"></svg-icon></p>
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item><img src="../../../src/assets/images/banner01.png"></van-swipe-item>
        <van-swipe-item><img src="../../../src/assets/images/banner02.png"></van-swipe-item>
        <van-swipe-item><img src="../../../src/assets/images/banner03.png"></van-swipe-item>
      </van-swipe>
      <div v-if="show">
        <div class="disease-top">
          <div class="title">
            <div @click="active = '0'" :class="{active:active=='0'}">疾病概述</div>
            <div @click="active = '1'" :class="{active:active=='1'}">治疗方式</div>
          </div>
          <div class="list-box">
            <div v-if="active=='0'">{{basic[0].value.diseaseDescription}}</div>
            <div v-if="active=='1'">{{basic[0].value.treatmentMode}}</div>
          </div>
        </div>
        <div class="tab-box">
          <div class="tab-title">
            <div @click="boxActive = '0'" :class="{active:boxActive=='0'}">随访方案</div>
            <div @click="boxActive = '1'" :class="{active:boxActive=='1'}">不良反应</div>
            <div @click="boxActive = '2'" :class="{active:boxActive=='2'}">伴随疾病</div>
          </div>
          <div class="box">
            <div v-if="boxActive=='0'">
              <div  class="boxa" v-for="item in followUp" :id="item.code" style="margin-top: .3rem">
                <div class="list-top"><svg-icon icon-class="jianchaxiangmu"></svg-icon> 检查项目</div>
                <div class="list">
                  <div>{{item.name}}</div>
                  <div>
                    <div class="title"><svg-icon icon-class="shijianpianlv"></svg-icon> 时间频率</div>
                    {{item.time}}
                  </div>
                  <div v-if="item.center !=''">
                    <div class="title"><svg-icon icon-class="pinggujieguo"></svg-icon> 结果评估标准</div>
                    {{item.center}}
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 1.5rem;"></div>
              <div class="buliangfanying">
                <div  v-for="item in suifangList" :class="{active : isActive == item.code}" @click="geclickNumt(item.code)">
                 <p v-if="isActive !== item.code"><svg-icon :icon-class="item.icon"></svg-icon></p>
                 <p v-else><svg-icon :icon-class="item.iconActive"></svg-icon></p>
                  {{item.name}}
                </div>
              </div>
            </div>
            <div v-if="boxActive=='1'">
              <div  class="boxa" v-for="item in  adverse" :id="item.code" style="margin-top: .3rem">
                <div class="list-top"><svg-icon icon-class="jibingmingcheng"></svg-icon> {{item.name}}</div>
                <div class="list">
                  <div style="margin-top: .4rem" v-if="item.center">
                    {{item.center}}
                  </div>
                  <div v-if="item.prevention">
                    <div class="title"><svg-icon icon-class="jibingyufang"></svg-icon> 疾病预防</div>
                    {{item.prevention}}
                  </div>
                  <div v-if="item.operation">
                    <div class="title"><svg-icon icon-class="life"></svg-icon> 手术治疗</div>
                    {{item.operation}}
                  </div>
                  <div v-if="item.life">
                    <div class="title"><svg-icon icon-class="life"></svg-icon> 生活方式干预</div>
                    {{item.life}}
                  </div>
                  <div v-if="item.medicine">
                    <div class="title"><svg-icon icon-class="yaowuzhiliao"></svg-icon> 药物治疗</div>
                    {{item.medicine}}
                  </div>
                  <div v-if="item.followup">
                    <div class="title"><svg-icon icon-class="suifangzhiliao"></svg-icon> 随访检测</div>
                    {{item.followup}}
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 1.5rem;"></div>
              <div class="buliangfanying">
                <div v-for="item in adverseList" :class="{active : isActive == item.code}" @click="geclickNumt(item.code)">
                  <p v-if="isActive !== item.code"><svg-icon :icon-class="item.icon"></svg-icon></p>
                  <p v-else><svg-icon :icon-class="item.iconActive"></svg-icon></p>
                  {{item.name}}
                </div>
              </div>
            </div>
            <div v-if="boxActive=='2'">
              <div  class="boxa" v-for="item in accompany" :id="item.code" style="margin-top: .3rem">
                <div class="list-top"><svg-icon icon-class="jibingmingcheng"></svg-icon> 疾病名称</div>
                <div class="list">
                  <div>{{item.name}}</div>
                  <div>
                    <div class="title"><svg-icon icon-class="jibingyufang"></svg-icon> 疾病预防</div>
                    {{item.center}}
                  </div>
                  <div>
                    <div class="title"><svg-icon icon-class="life"></svg-icon> 生活方式干预</div>
                    {{item.life}}
                  </div>
                  <div>
                    <div class="title"><svg-icon icon-class="yaowuzhiliao"></svg-icon> 药物治疗</div>
                    {{item.medicine}}
                  </div>
                  <div v-if="item.followup!=''">
                    <div class="title"><svg-icon icon-class="suifangzhiliao"></svg-icon> 随访检测</div>
                    {{item.followup}}
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 1.5rem;"></div>
              <div class="buliangfanying">
                <div v-for="item in jibingList" :class="{active : isActive == item.code}" @click="geclickNumt(item.code)">
                  <p v-if="isActive !== item.code"><svg-icon :icon-class="item.icon"></svg-icon></p>
                  <p v-else><svg-icon :icon-class="item.iconActive"></svg-icon></p>
                  {{item.name}}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {sessionState} from "../../api";

export default {
  name: 'disease',
  data() {
    return {
      isActive:"",
      show:false,
      active:'0',
      boxActive:'0',
      suifangList:[
        {
          icon:"changguijiancha",
          iconActive:"changguijianchaActive",
          code:"changgui",
          name:'常规检查'
        },
        {
          icon:"yingxiang",
          iconActive:"yingxiangActive",
          code:"yingxiang",
          name:'影像学检查'
        },
        {
          icon:"dianshengli",
          iconActive:"yingxiangActive",
          code:"dianshengli",
          name:'电生理检查'
        },
        {
          icon:"shiyanshi",
          iconActive:"yingxiangActive",
          code:"shiyanshi",
          name:'实验室检查'
        }
      ],
      typeValue:[
        {
          title:'Luminal A型',
          value:{
            diseaseDescription:'LuminalA型的IHC病理表现为雌激素受体（ER）和/或孕激素受体（PR）呈阳性，并且Her-2阴性、Ki-67＜14%。一般是指早期乳腺癌，是乳腺癌中常见的一种类型，在50岁以上的人群发生率更高。',
            treatmentMode:"Luminal A型具有内分泌敏感性，因此内分泌治疗效果较好。雌激素受体（ER）呈阳性的患者经过内分泌治疗的治疗有效率在50%~60%，如果患者的LuminalA型乳腺癌是雌激素受体（ER）和孕激素受体（PR）双阳性，则内分泌治疗的治疗效果更高可以达到80%。这个类型的乳腺癌治疗效果较好，且不易复发，使用化疗的治疗效果不佳，所以在临床治疗中对于这个类型的治疗一般不使用化疗。"
          }
        },
        {
          title:'Luminal B Her2阴型',
          value:{
            diseaseDescription:'LuminalB型（Her-2阴性）的IHC病理表现为雌激素受体（ER）和/或孕激素受体（PR）呈阳性，并且Her-2阴性、Ki-67≥14%。',
            treatmentMode:"Luminal B型（Her-2阴性）的肿瘤细胞的增殖速度较快，推荐大部分病人在内分泌治疗的基础上酌情选择是否化疗。"
          }
        },
        {
          title:'Luminal B Her2阳型',
          value:{
            diseaseDescription:'LuminalB型（Her-2阳性）的IHC病理表现为雌激素受体（ER）和/或孕激素受体（PR）呈阳性，并且Her-2过表达。',
            treatmentMode:"Luminal B型（Her-2阳性）属内分泌治疗敏感型，但是因为此型存在Her-2扩增，对他莫昔芬的反应较Luminal A型差，而对芳香化酶抑制剂类药物有效率高达88%。因此，对绝经后Luminal B型病人应首选芳香化酶抑制剂，绝经前病人应在促性激素释放激素类似物的基础上加芳香化酶抑制剂治疗，同时联合针对Her-2的靶向治疗才能获得最佳疗效。"
          }
        },
        {
          title:'Her2过表达型',
          value:{
            diseaseDescription:'Her-2过表达型的IHC病理表现为Her-2过表达，且雌激素受体（ER）和孕激素受体（PR）呈阴性，属于治疗后生存较差复发风险较高的乳腺癌。',
            treatmentMode:"对于Her-2过表达型乳腺癌，由于雌激素受体（ER）和孕激素受体（PR）表达过低或几乎无表达，内分泌治疗无效。目前主要采用化疗联合靶向治疗为主的治疗方式，靶向药物一般使用曲妥珠单抗。另外，靶向治疗的应用在其新辅助治疗策略中至关重要。"
          }
        },
        {
          title:'三阴性（-）（TNBC）',
          value:{
            diseaseDescription:'三阴性乳腺癌的IHC病理表现为雌激素受体（ER）、孕激素受体（PR）和Her-2呈阴性。这类乳腺癌多见于年轻女性，预后效果较差，恶性程度较高，无病生存时间较短，是所有乳腺癌中治疗效果最低的分型。',
            treatmentMode:"由于其ER、PR和Her-2均为阴性，所以内分泌治疗、靶向药物治疗都无法取得较好的治疗效果，一般为在术后进行辅助性化疗，但是目前还未发现对此类肿瘤治疗效果较好的化疗药物。"
          }
        },
      ],
      followUp:[
        {
          type:"常规检查",
          code:"changgui",
          name:'临床体检',
          center:"包括乳腺、胸壁、淋巴结和初始有症状的部位区域；若乳腺新发肿块、胸壁出现皮疹或结节、新发淋巴结肿大，则应进行组织病理学活检；其他部位新出现症状或有新发病灶，则给予相应检查。",
          time:'最初2年每3~6个月1次，其后3年每6个月1次，5年后每年1次'
        },
        {
          type:"常规检查",
          code:"changgui",
          name:'妇科检查',
          center:"子宫＋双侧附件（应用他莫昔芬者需检查有无子宫内膜增厚，排查子宫内膜癌）。",
          time:'应用他莫昔芬的患者每年1次'
        },
        {
          type:"影像学检查",
          code:"yingxiang",
          name:'乳腺超声',
          center:"0级：需要结合其他影像学检查进一步评估。\n" +
              "1级：阴性。超声影像未见异常，如无肿块、无结构扭曲、无皮肤增厚及无微小钙化等。\n" +
              "2级：良性病灶。基本上可以排除恶性病变。根据年龄及临床表现可每6~12个月随诊。\n" +
              "3级：可能良性病灶。恶性可能性＜2%。建议短期复查(3~6个月)及加做其他检查。\n" +
              "4级：可疑恶性病灶。恶性可能性为2%~95%。建议活检。\n" +
              "5级：高度怀疑恶性。恶性可能性≥95%。建议活检及临床采取适当措施。",
          time:'每6个月1次'
        },
        {
          type:"影像学检查",
          code:"yingxiang",
          name:'乳腺Ｘ线',
          center:"0级：需要结合其他影像学检查进一步评估。\n" +
              "1级：阴性。超声影像未见异常，如无肿块、无结构扭曲、无皮肤增厚及无微小钙化等。\n" +
              "2级：良性病灶。基本上可以排除恶性病变。根据年龄及临床表现可每6~12个月随诊。\n" +
              "3级：可能良性病灶。恶性可能性＜2%。建议短期复查(3~6个月)及加做其他检查。\n" +
              "4级：可疑恶性病灶。恶性可能性为2%~95%。建议活检。\n" +
              "5级：高度怀疑恶性。恶性可能性≥95%。建议活检及临床采取适当措施。",
          time:'每年1次'
        },
        {
          type:"影像学检查",
          code:"yingxiang",
          name:'胸片或胸部CT',
          center:"排查有无肺转移、淋巴结转移、胸腔积液、胸膜增厚、心包积液、胸壁肿块等转移征象。",
          time:'每年1次'
        },
        {
          type:"影像学检查",
          code:"yingxiang",
          name:'腹部超声',
          center:"排查有无肝脏占位、腹腔淋巴结肿大、腹水等转移征象。",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"影像学检查",
          code:"yingxiang",
          name:'骨扫描',
          center:"骨转移可见局部骨组织代谢异常。",
          time:'存在腋窝淋巴结转移>4枚等高危因素的患者，行基线骨扫描检查，必要时全身骨扫描每1~2年1次'
        },
        {
          type:"电生理检查",
          code:"dianshengli",
          name:'心电图',
          center:"",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"电生理检查",
          code:"dianshengli",
          name:'电生理检查',
          center:"",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"实验室检查",
          code:"shiyanshi",
          name:'血常规',
          center:"",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"实验室检查",
          code:"shiyanshi",
          name:'血生化',
          center:"",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"实验室检查",
          code:"shiyanshi",
          name:'乳腺肿瘤标志物',
          center:"如CEA、CA125、CA153等。",
          time:'每6个月1次，3年后每年1次'
        },
        {
          type:"实验室检查",
          code:"shiyanshi",
          name:'心肌标志物',
          center:"",
          time:'每6个月1次，3年后每年1次'
        },
      ],
      adverseReaction:[
        {
          type:'手术',
          code:'shoushu',
          name:'上肢淋巴水肿',
          center:'上肢淋巴水肿是乳腺癌患者常见的不良反应，多见于接受腋窝淋巴结清扫或放疗的患者，主要与手术清扫范围、淋巴引流区放疗、放疗剂量、患者体质和年龄等因素有关，临床上可表现为患侧上肢肿胀、疼痛、周径增粗、肢体变形、功能障碍，可继发感染。随诊随访期间可以通过询问患者主观感受、客观测量患侧上肢臂围等方式评估上肢淋巴水肿的情况，一般认定患侧上肢周径比对侧上肢周径长＜3cm为轻度水肿，3~5cm为中度水肿，＞5cm为重度水肿。',
          prevention:'使患侧上肢避免高温环境；避免负重；避免上肢近端受压；睡眠时使患侧上肢高过心脏平面；患肢避免有创性操作、避免感染；尽快恢复手臂功能；乘坐飞机或长途旅行时戴弹力袖套；适当进行体育锻炼等。',
          operation:'上肢淋巴水肿一般可通过人工淋巴引流、皮肤护理、多层绷带压迫、按摩理疗、功能锻炼等恢复。但对于重度淋巴水肿患者，需采取淋巴结移植、建立淋巴管-静脉吻合等方式进行治疗。患侧上肢红肿热痛或水肿突然加重者，应及时排查继发感染；确诊继发感染者，应及时进行抗炎处理。',
        },
        {
          type: '手术',
          code:'shoushu',
          name: '臂丛神经损伤',
          center: '乳房切除术、腋窝淋巴结活检及切除术、针对锁骨上区和腋部放疗，可能会导致臂丛神经或上肢近端神经的急性或迟发损伤，通常表现为手术侧或放疗侧上肢持续加重的麻木疼痛和无力，随诊随访时应给予关注。治疗方面，以对症支持为主，可使用神经营养药物及镇痛药物，辅以物理功能锻炼。',
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '子宫内膜增厚',
          center: '针对使用内分泌药物(尤其是他莫昔芬)治疗的患者应关注子宫内膜增厚的问题。当出现子宫内膜检查结果异常时，首先应判断是否与内分泌药物(如他莫昔芬)相关，随后依据评估结果给予适当的治疗。',
          operation:"①子宫内膜息肉：宫腔镜切除；\n" +
              "②子宫内膜增生不伴不典型增生：绝经前，换用GnRHa+AI；绝经后，换用AI或托瑞米芬；放宽子宫+双侧附件切除术指征。\n" +
              "③子宫内膜不典型增生：无生育要求，行腹腔镜子宫+双侧附件切除术；强烈生育要求，GnRHa±左炔诺孕酮宫内缓释系统或GnRHa±AI，同时，每3个月进行1次宫腔镜检查+刮宫无症状、组织学连续2次阴性结果，评估间隔时间延长至6~12个月。\n" +
              "④子宫内膜癌：治疗原则同普通子宫内膜癌，BRCA1/2和林奇综合征基因检测。",
          followup:"针对已出现子宫内膜增厚的患者，应提高随诊频率，建议每3~6个月进行检测。"
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '静脉血栓',
          center: '乳腺癌患者发生静脉血栓栓塞症（VTE）的风险是同年龄段非肿瘤患者的3.5倍，尤其是接受他莫昔芬治疗的患者，应注意VTE的预防和干预。',
          prevention:"①加强健康教育；足踝主动或被动运动，被动挤压小腿肌群；注意尽早下床活动；避免脱水。\n" +
              "②无抗凝药物禁忌证的住院患者可预防性应用抗凝药物，如普通肝素、低分子肝素，有抗凝药物禁忌证的患者可采用机械预防，如间歇充气压迫装置、分级加压弹力袜(下肢动脉供血不足者慎用)、足底静脉泵。",
          medicine:"对于已经发生VTE的肿瘤患者，抗凝治疗至少进行3个月或贯穿整个患癌和抗癌治疗期间。首次抗凝治疗后的前14d至少每2~3d检测1次血红蛋白、血细胞比容和血小板技术，此后每2周复查1次或根据临床指征进行检测。",
          operation:"发生VTE但对抗凝治疗具有绝对禁忌证的患者，可行下腔静脉滤器(IVCF)置入术，以预防下腔静脉系统血栓栓子脱落引起肺动脉栓塞。"
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '药物性肝损伤',
          center: '药物性肝损伤(DILI)是常见且严重的药物不良反应之一，重者可致急性肝衰竭甚至死亡。肝功能检测异常患者可通过以下方式进行干预。',
          medicine:"①若怀疑存在DILI时应及时停药，停药标准可参考美国食品药品监督管理局药物临床试验中出现DILI的停药原则：①血清谷氨酸氨基转移酶(ALT)或天门冬氨酸氨基转移酶(AST)＞8倍正常上限(ULN)；②ALT或AST＞5倍ULN，持续2周；③ALT或AST＞3倍ULN，且总胆红素＞2ULN或国际标准化比值＞1.5；④ALT或AST＞3倍ULN，伴逐渐加重的疲劳、恶心、呕吐、右上腹疼痛或压痛、发热、皮疹和(或)嗜酸性粒细胞增多(＞5%)。\n" +
              "②应充分权衡停药引起原发病进展和继续用药导致肝损伤加重的风险，同时根据DILI的临床类型选用适当的药物治疗。",
          followup:"提高肝功能随诊频率，建议每3~6个月复查。"
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '血脂异常',
          center: '超过半数的乳腺癌患者处于围绝经期或绝经期。随着雌激素水平下降，血脂异常的发生率明显上升。除辅助内分泌治疗外，辅助化疗亦会升高乳腺癌患者的血脂水平。根据LDL-C管理目标，针对不同患者给予个体化治疗方案。',
          life:"①戒烟：不吸烟、避免吸二手烟，可使用尼古丁替代或戒烟药物。\n" +
              "②调整饮食结构：增加多种水果、蔬菜摄入，选择全谷物或高纤维食物，限制饱和脂肪酸、反式不饱和脂肪酸、胆固醇、酒精和糖的摄入。\n" +
              "③保持理想体重或减重：通过运动、控制饮食和行为训练维持或减轻体重，保持体质指数(BMI)≥20kg/m2且≤24kg/m2，且腰围＜80cm。\n" +
              "④运动：每周坚持150min中等强度的有氧运动，如走路、慢跑、骑车、游泳、跳舞等，绝经后女性应每周进行2次肌肉张力锻炼。",
          medicine:"①结合临床疾患和(或)危险因素决定是否开始降脂药物治疗。他汀类是临床中最常用的降脂药物，且他汀类药物与内分泌药物间无相互作用，其他降脂药物则多在必要时作为他汀类药物联合用药的选择。\n" +
              "②选择适当的内分泌治疗药物。",
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '骨质疏松',
          center: '在绝经后雌激素水平降低的影响下，自然绝经的女性骨密度(BMD)每年下降1.9%，容易出现骨量减少和骨质疏松及骨折。绝经前乳腺癌患者如接受卵巢去势、芳香化酶抑制剂(AI)或化疗等治疗时，也会增加骨折风险。根据BMD和临床风险因素，给予患者个体化治疗方案。',
          life:"建议每日≥30min中等强度的运动，如步行、跑步等，鼓励负重运动；提倡户外活动，增加日光照射；进食含钙丰富的食物；戒烟戒酒；特别注意防止跌倒和身体猛烈撞击。",
          medicine:"①补充钙剂和维生素D对于维持于BMD极为重要；地舒单抗60mg每6个月1次是预防骨折可选的治疗。\n" +
              "②建议选择对骨安全影响较小的内分泌药物，以降低骨安全问题的发生。",
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '骨骼肌肉症状',
          center: 'BMD降低、骨转移和治疗相关药物等均可导致患者出现疼痛症状。出现肌肉骨骼疼痛的患者可通过以下方式改善症状：',
          life:"运动(包括有氧训练、力量训练、瑜伽)、针灸均可以在一定程度上改善接受内分泌治疗乳腺癌患者肌肉骨骼疼痛的症状。",
          medicine:"目前，针对由AI类药物所致的疼痛，常选择对乙酰氨基酚或非甾体抗炎药(NSAIDs)治疗。不推荐长期或大剂量应用NSAIDs控制疼痛，亦不推荐阿片类药物用于管理AI相关肌肉骨骼疼痛。",
          followup:"应提高患者肌肉疼痛的监测频率，建议每3~6个月进行随诊随访。对于尝试多种方法仍无法改善的AI相关肌肉骨骼疼痛，需重新评估内分泌治疗的绝对获益，讨论是否继续内分泌治疗。"
        },
        {
          type: '内分泌治疗',
          code:'neifenmi',
          name: '更年期综合征',
          center: '更年期综合征是指妇女在绝经及其前后的一段时间，由于雌激素水平降低或波动而出现的一系列躯体及精神心理症状，包括潮热、多汗、失眠、情绪低落、激动易怒、肌肉骨骼疼痛等。绝经前乳腺癌患者接受化疗、内分泌治疗等可能会造成卵巢功能抑制、雌激素水平降低，提前发生更年期综合征。',
          life:"①对于AI类药物或他莫昔芬治疗的患者，体重增加与潮热的发生风险独立相关，因此，控制体重和饮食可能是改善乳腺癌患者潮热症状的重要策略。星状神经节阻滞手术改善潮热症状的效果与普瑞巴林和帕罗西汀相当，但考虑其有创性，不作为首选的治疗策略。\n" +
              "②认知行为疗法、催眠疗法可以有效改善患者的血管舒缩症状，同时改善患者的精神心理状态与睡眠问题。\n" +
              "③针灸、瑜伽和放松训练均可以在一定程度上改善乳腺癌患者的血管舒缩症状，同时帮助患者树立正确认知，调整生活方式，必要时进行心理疏导。",
          medicine:"患者症状较重，非药物治疗未能缓解，可予以适当药物治疗。\n" +
              "①激素替代治疗：局部应用雌激素对缓解泌尿生殖道萎缩症状或阴道干涩等症状疗效确切，长期治疗可获得持续有效性，尚未发现局部低剂量雌激素有全身不良反应。全身激素替代治疗会增加乳腺癌患者的复发风险，因此不推荐应用于乳腺癌患者。\n" +
              "②非激素类药物：该类药物主要为植物中提取的植物雌激素，不与雌激素受体结合，直接调节中枢神经系统发挥作用。黑升麻提取物、莉芙敏和希明婷均为此类药物。\n" +
              "③其他辅助药物：随机对照研究证实，抗抑郁药(如文拉法辛、度洛西汀、帕罗西汀、舍曲林等)可以显著改善乳腺癌患者的潮热症状，对于他莫昔芬治疗的患者，推荐选择影响代谢小的药物，如文拉法辛。抗惊厥药物加吧喷丁和普瑞巴林是有效的替代治疗，与文拉法辛的作用相当。α受体阻滞剂可乐定、抗胆碱能药物奥昔布宁也可以改善患者的潮热症状，但在临床上应平衡其利弊，谨慎做出选择。",
        },
        {
          type: '化疗',
          code:'hualiao',
          name: '蒽环类药物心脏不良反应',
          center: '化疗相关不良反应，通常会在患者化疗终止后逐渐消失。但是，蒽环类药物可能导致长期的心脏不良反应，影响患者的生活质量。心功能正常患者，每年定期进行随诊随访，同时加强对乳腺癌患者心血管疾病的了解。心功能异常患者，除积极的生活干预外，还需进行综合干预。',
          medicine:"①疑似存在心功能异常，可使用血管紧张素转换酶抑制剂(ACEI)、血管紧张素受体阻滞剂和特定β受体阻滞剂，防止蒽环类药物诱导的心肌病发生。\n" +
              "②如果患者使用蒽环类药物期间发生有临床症状的心脏不良反应，或无症状但左室射血分数(LVEF)<45%亦或较基线下降幅度>15%，可考虑检测心肌肌钙蛋白T，必要时应先停药并充分评估患者的心脏功能，后续治疗应慎重。",
          followup:'在蒽环类药物化疗结束后仍应继续对患者心功能进行随诊随访，高危患者则应提高随诊随访频率，可通过超声心动图或血管造影术、肌钙蛋白、脑钠肽和心内膜心肌活检等进行评估。'
        },
        {
          type: '化疗',
          code:'hualiao',
          name: '紫杉类药物神经不良反应',
          center: '化疗相关不良反应，通常会在患者化疗终止后逐渐消失。但是，蒽环类药物可能导致长期的心脏不良反应，影响患者的生活质量。心功能正常患者，每年定期进行随诊随访，同时加强对乳腺癌患者心血管疾病的了解。心功能异常患者，除积极的生活干预外，还需进行综合干预。',
          medicine:"①疑似存在心功能异常，可使用血管紧张素转换酶抑制剂(ACEI)、血管紧张素受体阻滞剂和特定β受体阻滞剂，防止蒽环类药物诱导的心肌病发生。\n" +
              "②如果患者使用蒽环类药物期间发生有临床症状的心脏不良反应，或无症状但左室射血分数(LVEF)<45%亦或较基线下降幅度>15%，可考虑检测心肌肌钙蛋白T，必要时应先停药并充分评估患者的心脏功能，后续治疗应慎重。",
          followup:'在蒽环类药物化疗结束后仍应继续对患者心功能进行随诊随访，高危患者则应提高随诊随访频率，可通过超声心动图或血管造影术、肌钙蛋白、脑钠肽和心内膜心肌活检等进行评估。'
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '心脏损伤',
          center: '放疗可能引起心包、心肌、冠状动脉、心脏传导系统等多种心脏组织结构的损伤。放射性心脏损伤与心脏照射体积、照射剂量以及化疗和靶向药物的应用有关。',
          medicine:"对于已经出现放疗相关心脏不良反应的患者，可尝试应用抗炎、抗血栓、营养心肌、他汀类药物和ACEI等治疗，其临床应用价值仍需进一步探索｡",
          followup:'放疗相关心脏不良反应的潜伏期较长，早期缺乏明显的临床表现，应在放疗后长时间内随诊心肌酶、心电图、超声心动图、冠脉CT血管成像技术、心肌核素等辅助检查，以及时发现心包病变、心肌病、冠状动脉病变、心律失常等。目前尚缺乏放疗相关心脏不良反应的特异性治疗方案。因此，在制定放疗决策时，应尽量降低正常心脏组织的照射体积和照射剂量，并减少放疗相关心脏不良反应的危险因素。'
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '放射性肺炎',
          center: '放射性肺炎通常发生于放疗后的1~6个月内，临床可表现为发热、刺激性干咳、胸痛、呼吸困难等，多数患者只有影像学改变，缺乏临床症状。放射性肺炎的发生与患者基础肺部疾病与肺功能、肺部照射体积、肺部照射剂量以及同步化疗有关。制定放疗决策时，应尽量降低正常肺组织的照射体积和照射剂量。',
          medicine:"①对于已经发生放射性肺炎的患者，应根据患者个体情况，给予止咳、祛痰、抗感染、吸氧等对症处理；对于重症患者，应用糖皮质激素，以抑制免疫、减少渗出、抑制促纤维化因子产生，当临床症状明显好转后可逐渐减量至停用。\n" +
              "②目前尚缺乏对于糖皮质激素初始剂量、维持时间和减量速度的标准，我国学者根据临床经验推荐如下：(1)建议使用地塞米松或泼尼松等长效糖皮质激素；(2)地塞米松可以5mg的较低剂量起始，分2次口服或静脉用药，前2~3d症状改善不明显者，可每2.5mg增加至10mg，但更高剂量的改善是有限的；(3)可在用药后的前2~3d行疗效评价，症状明显缓解即可，如基本正常或明显改善，并作为维持剂量，否则可增加≤2.5mg的剂量，再次评价效果，以得到较小而有效的剂量作为维持剂量；(4)维持剂量治疗3~4周，之后缓慢减量，如2~3d减量1片或2d减半片直至全部停药。",
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '上肢淋巴水肿',
          center:'上肢淋巴水肿是乳腺癌患者常见的不良反应，多见于接受腋窝淋巴结清扫或放疗的患者，主要与手术清扫范围、淋巴引流区放疗、放疗剂量、患者体质和年龄等因素有关，临床上可表现为患侧上肢肿胀、疼痛、周径增粗、肢体变形、功能障碍，可继发感染。随诊随访期间可以通过询问患者主观感受、客观测量患侧上肢臂围等方式评估上肢淋巴水肿的情况，一般认定患侧上肢周径比对侧上肢周径长＜3cm为轻度水肿，3~5cm为中度水肿，＞5cm为重度水肿。',
          prevention:'使患侧上肢避免高温环境；避免负重；避免上肢近端受压；睡眠时使患侧上肢高过心脏平面；患肢避免有创性操作、避免感染；尽快恢复手臂功能；乘坐飞机或长途旅行时戴弹力袖套；适当进行体育锻炼等。',
          operation:'上肢淋巴水肿一般可通过人工淋巴引流、皮肤护理、多层绷带压迫、按摩理疗、功能锻炼等恢复。但对于重度淋巴水肿患者，需采取淋巴结移植、建立淋巴管-静脉吻合等方式进行治疗。患侧上肢红肿热痛或水肿突然加重者，应及时排查继发感染；确诊继发感染者，应及时进行抗炎处理。',
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '臂丛神经损伤',
          center: '乳房切除术、腋窝淋巴结活检及切除术、针对锁骨上区和腋部放疗，可能会导致臂丛神经或上肢近端神经的急性或迟发损伤，通常表现为手术侧或放疗侧上肢持续加重的麻木疼痛和无力，随诊随访时应给予关注。治疗方面，以对症支持为主，可使用神经营养药物及镇痛药物，辅以物理功能锻炼。',
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '放射性皮肤损伤',
          center: '放射性皮肤损伤多在放疗3~4周后出现，可表现为皮肤色素沉着、毛囊扩张、汗毛脱落、红斑、水肿，甚至水疱、破溃。',
          medicine:"轻度放射性皮肤损伤可自行恢复，无需治疗。较为严重的放射性皮肤损伤可以局部涂抹激素类药膏，或采用激素+抗生素联合治疗。",
          prevention:"放疗期间可通过以下措施预防放射性皮肤损伤：穿着宽大柔软的内衣、避免皮肤摩擦、保持照射区域皮肤清洁干燥、避免阳光和紫外线照射等。"
        },
        {
          type: '放疗',
          code:'fangliao',
          name: '乳腺纤维化',
          center: '乳腺纤维化表现为全乳腺或乳腺局部质地变硬，同皮下组织纤维化相似，其发生与放疗技术与放疗剂量、遗传因素、结缔组织疾病、同步全身治疗、糖尿病有关。',
          prevention:"乳腺纤维化的管理应以预防为主。现代放疗技术的发展和普及在一定程度上避免了乳腺纤维化的发生，医师应根据患者的具体情况权衡肿瘤控制和美容效果的利弊，为患者选择个体化的放疗技术与剂量。"
        },
        {
          type: '靶向治疗',
          code:'baxiang',
          name: '抗HER-2心脏不良反应',
          center: '曲妥珠单抗诱导的心肌病大多表现为治疗期间LVEF无症状性下降，有症状的显性心力衰竭并不常见。与蒽环类药物相反，曲妥珠单抗诱导的心脏功能不全似乎并不具有剂量依赖性，停止治疗后心脏不良反应通常可逆转。',
          followup:"①采用曲妥珠单抗或帕妥珠单抗或恩美曲妥珠单抗治疗时，推荐在基线以及治疗过程中每隔3个月行超声心动图检查，评估LVEF。\n" +
              "②若发现患者心功能出现异常，除积极的生活干预外，应立即停用相关治疗药物，同时还应给予适当的医疗管理，使不良反应降到最小。如果LVEF较基线下降>15%或下降10%且低于50%，在重新评估LVEF前应暂停使用相关治疗药物4周。若LVEF依然处于低水平或有症状性心力衰竭证据，则应终止相关药物治疗。此外，还应加强患者的随诊随访频率，建议每个月进行检测。"
        }
      ],
      jibingList:[
        {
          icon:"xuezhi",
          iconActive:"xuezhiActive",
          code:"xuezhi",
          name:'血脂异常'
        },
        {
          icon:"guzhi",
          iconActive:"guzhiActive",
          code:"guzhi",
          name:'骨质疏松'
        },
        {
          icon:"jingshen",
          iconActive:"jingshenActive",
          code:"jingshen",
          name:'精神心理'
        }
      ],
      accompany:[
          {
            name:'血脂异常',
            code:"xuezhi",
            center:"超过半数的乳腺癌患者处于围绝经期或绝经期。随着雌激素水平下降，血脂异常的发生率明显上升。除辅助内分泌治疗外，辅助化疗亦会升高乳腺癌患者的血脂水平。根据LDL-C管理目标，针对不同患者给予个体化治疗方案。",
            life:"①戒烟：不吸烟、避免吸二手烟，可使用尼古丁替代或戒烟药物。\n" +
                "  ②调整饮食结构：增加多种水果、蔬菜摄入，选择全谷物或高纤维食物，限制饱和脂肪酸、反式不饱和脂肪酸、胆固醇、酒精和糖的摄入。\n" +
                "  ③保持理想体重或减重：通过运动、控制饮食和行为训练维持或减轻体重，保持体质指数(BMI)≥20kg/m2且≤24kg/m2，且腰围＜80cm。\n" +
                "  ④运动：每周坚持150min中等强度的有氧运动，如走路、慢跑、骑车、游泳、跳舞等，绝经后女性应每周进行2次肌肉张力锻炼。",
            medicine:"①结合临床疾患和(或)危险因素决定是否开始降脂药物治疗。他汀类是临床中最常用的降脂药物，且他汀类药物与内分泌药物间无相互作用，其他降脂药物则多在必要时作为他汀类药物联合用药的选择。\n" +
                "  ②选择适当的内分泌治疗药物。",
            followup:""

          },
        {
          name:'骨质疏松',
          code:"guzhi",
          center:"在绝经后雌激素水平降低的影响下，自然绝经的女性骨密度(BMD)每年下降1.9%，容易出现骨量减少和骨质疏松及骨折。绝经前乳腺癌患者如接受卵巢去势、芳香化酶抑制剂(AI)或化疗等治疗时，也会增加骨折风险。根据BMD和临床风险因素，给予患者个体化治疗方案。",
          life:"建议每日≥30min中等强度的运动，如步行、跑步等，鼓励负重运动；提倡户外活动，增加日光照射；进食含钙丰富的食物；戒烟戒酒；特别注意防止跌倒和身体猛烈撞击。",
          medicine:"①补充钙剂和维生素D对于维持于BMD极为重要；地舒单抗60mg每6个月1次是预防骨折可选的治疗。\n" +
              "②建议选择对骨安全影响较小的内分泌药物，以降低骨安全问题的发生。",
          followup:""

        },
        {
          name:'精神心理问题',
          code:"jingshen",
          center:"抑郁与焦虑是乳腺癌患者最常见的精神心理问题，患病率明显高于普通人群。一方面，抑郁会降低患者的生活质量，另一方面，有抑郁史的患者更容易早期停止辅助内分泌治疗，进而影响预后。因此，有必要在治疗中关注患者的精神心理状态，进行必要的监测及治疗。",
          life:"保持健康的体重、有规律地参加体力活动、调整膳食结构、戒烟戒酒等，同时应每年对患者进行1次精神状态评估。心理教育、支持性团体、放松疗法和体育锻炼均是对抗焦虑抑郁的行为方式。",
          medicine:"①对于中重度患者应该转诊精神科或使用抗焦虑抑郁的药物。\n" +
              "②由于某些抗抑郁药影响乳腺癌治疗药物他莫昔芬的代谢，所以药物推荐选择影响代谢小的药物，如西酞普兰、艾司西酞普兰、米氮平和文拉法辛。需注意药物间相互作用、权衡利弊，注意个体化和灵活性，重视心理治疗。",
          followup:"关于监测频率并无明确要求，临床研究中监测频率常较高(每3~6个月1次)，在临床治疗中，可根据实际情况安排。如已明确诊断为焦虑和抑郁障碍者，可在诊治早期安排较频繁的监测(每3~6个月)，后期逐渐延长监测间隔时间(每1~3年)。"

        }
      ],
      basic:{},
      adverse:[],
      adverseList:[],
      tubercleShow:'0',
      tubercle:[
          {
        code:"0",
        type:"乳腺结节",
        summary:"乳腺结节是一种症状，常见于乳腺增生（可形成乳腺囊肿）及乳腺肿瘤性疾病，包括乳腺良性肿瘤（如乳腺纤维瘤、分叶状肿瘤等）以及乳腺恶性肿瘤（乳腺癌）。",
        diagnosis:"常用诊断方法有：（1）乳腺触诊；（2）乳腺X线：可在乳腺钼靶片上见到圆形或不规则结节，良性结节一般边界清楚，形态规则，恶性结节一般边界不清，形态不规则，可见毛刺或伴分叶，有时结节外可见“水肿带”，对病灶内的簇状钙化显示较好。（3）乳腺超声：可清楚显示乳腺组织内较小病灶，并可对肿块较完善地定性、定位。（4）病理组织学检查：结节可行空心针穿刺活检或切除活检进行组织学诊断，以确定结节性质。对于不可触及的病灶，可在钼钯或B超引导定位下进行。",
        followup:"（1）对于30岁以下、医生未发现可疑病灶的BI-RADS1-3类患者，每6~12个月检查一次，如果2年结节无变化或者缩小则不需要处理，但是结节增大或者BI-RADS级别增高，则可以穿刺或者切除活检。\n" +
            "（2）对于30岁以上、医生发现可疑病灶或者影像BI-RADS 4-5类可以予以穿刺活检或者手术。",
        prevention:"鉴于乳腺结节的病因尚不清楚，目前无明确有效预防手段，但可以针对乳腺癌的高危因素进行相关防控措施，包括合理饮食、健康生活方式、鼓励哺乳、戒烟戒酒、避免长期大量口服避孕药等。\n" +
            "如为遗传性乳腺癌患者，可考虑进行乳腺癌易感基因检测以明确，并制定个体化乳腺癌筛查方案，必要时行预防性乳腺切除术。",
        treatment:"乳腺结节的治疗应首先明确诊断，通过乳腺B超及钼靶，部分结节考虑为良性病变可能性大，可考虑予以定期随诊观察；部分乳腺结节需行活检明确病理性质，依据病理结果决定进一步治疗方案，如为恶性肿瘤，则需依据患者病情多学科协作，制定个体化综合治疗方案，包括手术、化疗、放疗、内分泌治疗及分子靶向治疗方案等。",
        risk:'乳腺结节的癌变率需要根据结节的大小、边界和活动性来判断，也可以根据超声检查时乳腺结节的分类来判断。BI-RADS 1-3级通常为良性，但也有恶性转化的概率，一般小于2%；4a级癌变率约为2%-11%。BI-RADS级别越高，癌变的风险越大'
      },
        {
        code:"1",
        type:"乳腺增生",
        summary:"乳腺增生症是乳腺正常发育和退化过程失常导致的乳腺结构紊乱的非肿瘤性病变。临床表现多样化，以乳腺疼痛为主，部分患者触诊为结节状态或肿块，少数患者合并乳头溢液。乳腺增生症多发生于30～50岁女性，致病原因主要内分泌功能紊乱。",
        diagnosis:"常用诊断方法有：（1）乳腺超声：对腺体丰富且年龄＜35岁的病人，首选彩色超声检查。（2）乳腺X线：发现早期癌和微小癌的重要手段。（3）乳管镜/乳管造影检查：针对乳头溢液的病人，可行乳管镜或乳管造影并结合细胞学检查进行鉴别诊断。（4）病理学检查：针对上述检查发现的乳腺肿块、局限性腺体增厚、可疑结节、微钙化等，行空芯针穿刺活检、细针穿刺细胞学检查或手术活检以明确诊断。（5）乳腺磁共振(MRI)、CT可作为对乳腺增生症进行定性并分型的辅助检查。",
        followup:"（1）18~39岁的女性每月一次乳房自我检查，3年一次临床体检；40岁以上每年一次。包括乳房X线检查临床体检。（2）对于高危人群（乳腺病家族史、卵巢癌、腺体癌、没有生育或生育年龄大于35岁、长期使用雌激素药物、年龄大于40岁)，应进行定期的正规检查，包括选择B超，或X线钼靶照片。有重度增生的女性应半年检查一次，进行动态观察。",
        prevention:"（1）保持情绪稳定，乐观开朗，生活劳逸结合，做到充实而有规律。月经不调者，建议及时治疗。\n" +
            "（2）分娩后争取哺乳，对孩子和自己都有好处。\n" +
            "（3）禁止滥用避孕药及含雌性激素类美容用品。\n" +
            "（4）合理健康饮食，避免饮用含咖啡因的饮料，含雌激素饲料喂养的家禽、淡水水产，少食油炸食品、过甜的食品和过于刺激的食物。",
        treatment:"充分的个体化心理及药物干预，结合必要的活检及适当的手术切除是乳腺增生症的有效治疗模式。治疗时应针对不同的临床表现及病理学类型予以分别对待。\n" +
            "·药物治疗\n" +
            "对于伴随轻至中度疼痛者以心理疏导及改变生活习惯为主，对于持续性存在的严重乳腺疼痛病人，可予药物治疗。但须注意，药物治疗不能有效缓解乳腺增生症的病理学改变，不能起到根治作用。\n" +
            "·介入治疗\n" +
            "对于超声提示的薄壁囊肿，细针穿刺抽吸是首选的治疗方式。抽吸液呈血性者或超声检查提示为复杂性囊肿时应警惕乳腺恶性病变，建议对血性抽吸液进行细胞学或病变部位的病理学检查。",
        risk:"不同病理学表现的乳腺增生症发生乳腺癌的危险性也不相同。其中乳腺囊性增生病的癌变率为1%～5%，只有活检证实为非典型增生时其发生乳腺癌的危险性才会明显增加。但约有80%的非典型增生病人终生都不会发展成为乳腺癌。目前，非典型导管增生及导管内乳头状瘤病被视为乳腺癌癌前病变。"
      },{
        code:"2",
        type:"乳腺囊肿",
        summary:"乳腺囊肿是临床上较为常见的一类良性疾病，发病率约为7.0%，好发于绝经前期及围绝经期的女性，部分囊肿较小或位置较深而难以触及，部分存在一定的恶变风险。乳腺囊肿可分为单纯性囊肿、乳汁潴留性乳腺囊肿。其中，单纯性囊肿较多见，主要是由于内分泌失调而引起；乳汁潴留性乳腺囊肿常见于青年妇女，发生于哺乳期或产后，主要是因炎症引起乳腺导管堵塞，乳汁潴留形成。",
        diagnosis:"常用诊断方法有：（1）乳腺触诊：可对乳房肿物及是否伴有其他乳房异常作出初步判断。因触诊无法对乳房肿物的性质作出判断，因而常需结合其他辅助检查手段。（2）乳腺B超：可确定乳房肿块是充满液体还是固体，对乳腺肿物性质作出判断。该检查对囊性肿物有较高的检出率，是检测乳腺囊肿的主要影像学检查手段。（3）细胞学检查：又称针吸细胞学，用细针穿刺囊状肿块，吸取的成分通过涂片检查，来鉴别乳腺囊肿的良、恶性。",
        followup:"临床多数乳腺囊性增生属于良性病变，定期复查乳腺彩超即可，若为单发或者单纯小囊肿，每年复查一次；若为较大、囊壁增厚或有少量血流信号的乳腺囊肿，应缩短复查时间，必要时进行穿刺活检以明确囊肿性质。",
        prevention:"（1）保持健康的生活方式，合理饮食，积极向上快乐的精神状态。\n" +
            "（2）饮食上减少含雌激素类、脂肪类、糖类等食物的摄入。\n" +
            "（3）应重视乳房自我检查。\n" +
            "（4）针对乳腺积乳囊肿，哺乳期应促进乳汁排出，避免急性乳腺炎和囊肿的发生。",
        treatment:"乳腺囊肿在多数情况下无需治疗，尤其是药物治疗，对乳腺囊肿没有明确疗效。临床以随诊观察为主。\n" +
            "·介入治疗\n" +
            "若囊肿体积较大，可在超声引导下穿刺抽出囊内液体以缓解症状。\n" +
            "·手术治疗\n" +
            "伴有不适症状的乳腺囊肿频繁复发，或乳腺囊肿囊内液体含血液，可考虑手术。目前多采用直空辅助乳腺微创旋切术治疗。",
        risk:"乳腺囊肿为常见良性疾病，一般情况下不会恶化，恶变率低于千分之一。部分囊肿随年龄增长、乳腺退化可自行消失。若囊肿体积较大，多可采用抽吸术治疗，一般预后良好。"
      },{
        code:"3",
        type:"乳腺纤维腺瘤",
        summary:"乳腺纤维腺瘤是最常见的乳腺良性肿瘤，占乳腺科门诊病人的7%~13%，可发生于青春期后任何年龄段的女性，发病高峰年龄为15~25岁。约25%的纤维腺瘤无症状，13%~20%为多发病灶，多发纤维腺瘤病人多有家族史。纤维腺瘤病程较长，多数病变缓慢增大或无变化，少数可自然消退或快速增大。",
        diagnosis:"乳腺纤维腺瘤的临床诊断主要依据触诊､超声和X线检查，不推荐MRI作为纤维腺瘤的常规影像学诊断方法。初步诊断为纤维腺瘤而影像学诊断BI-RADS4a类及以上的可疑恶性病灶，应取得病理学诊断。推荐空芯针穿刺活检。",
        followup:"\"（1）不伴高危因素的纤维腺瘤：大多数生长缓慢或无变化的纤维腺瘤患者，均建议随访观察。具体方案为2年内每6个月随访1次。推荐的检查方法为触诊结合彩超，对于年龄>35岁的患者，推荐加入每年1次乳腺X线检查作为随访检查手段。2年内病情稳定者，可考虑延长至每年随访1次。\n" +
            "（2）伴有乳腺癌高危因素的纤维腺瘤：建议密切随访，随访频率建议每3个月1次。随访2次，如病情稳定，后续参照不伴高危因素的纤维腺瘤随访方案。高危因素包括：40岁以上新发、乳腺癌病史或家族史和叶状肿瘤病史。\"",
        prevention:"（1）建立良好的生活饮食习惯，避免和减少心理紧张因素，保持心情舒畅。\n" +
            "（2）控制高脂肪、高热量饮食的摄入，不乱服用外源性雌激素。\n" +
            "（3）掌握乳房自我检查方法，养成每月一次的乳房自查习惯，若发现原因不明的乳腺结节，应及时去医院诊断。",
        treatment:"在随访过程中发现肿瘤生长迅速或RI-RADS分类升高时，建议外科干预。\n" +
            "·手术治疗\n" +
            "肿块>3cm或导致乳腺外形改变，可考虑外科干预。无外科干预指征，但过度焦虑或有强烈手术要求者，建议乳腺结节多学科会诊。外科干预的方法有开放肿瘤切除术和真空辅助微创旋切术。",
        risk:"纤维腺瘤中的上皮成分癌变风险极低，癌变率为0.12%～0.30%，且癌变者多为小叶原位癌。经手术切除后病理学检查确诊的纤维腺瘤病人乳腺癌的发病风险较普通女性略增高（1.48～1.70倍），伴有非典型增生或一级亲属乳腺癌家族史或复杂纤维腺瘤病人，其乳腺癌发病风险高于普通纤维腺瘤病人。因此，建议此类高危纤维腺瘤病人术后仍须定期行乳腺检查。"
      }],
      tubercleData:[],
      jiejieList:[
        // {
        //   icon:"summary",
        //   iconActive:"summaryActive",
        //   code:"summary",
        //   name:"疾病概述"
        // },
        // {
        //   icon:"diagnosis",
        //   iconActive:"diagnosisActive",
        //   code:"diagnosis",
        //   name:"诊断方法"
        // },
        {
          icon:"followup",
          iconActive:"followupActive",
          code:"followup",
          name:"随访观察"
        },
        {
          icon:"prevention",
          iconActive:"preventionActive",
          code:"prevention",
          name:"疾病预防"
        },
        {
          icon:"treatment",
          iconActive:"treatmentActive",
          code:"treatment",
          name:"治疗方法"
        },
        {
          icon:"risk",
          iconActive:"riskActive",
          code:"risk",
          name:"癌变风险"
        },
      ],
      symptoms:[]
    }
  },
   components: {

   },
  props: {

  },
  watch:{
    boxActive(){
      this.isActive = ''
    }
  },
  created() {
    if(this.$route.query.type){
      this.onTubercle()
    }else {
      this.getSessionState()
    }
  },
   mounted() {


  },
  methods: {
    getSessionState(){
      sessionState(this.$store.getters.session).then((res)=>{
        this.show = true
        this.basic = this.typeValue.filter(item => item.title === res.pathological_type)
        if(res.pathological_type == 'Luminal A型' || res.pathological_type == 'Luminal B Her2阴型' ){
          this.adverseList = [{
            icon:"shoushu",
            iconActive:"shoushuActive",
            code:'shoushu',
            name:"手术"
          },{
            icon:"neifenmi",
            iconActive:"neifenmiActive",
            code:'neifenmi',
            name:"内分泌治疗"
          },{
            icon:"hualiao",
            iconActive:"hualiaoActive",
            code:'hualiao',
            name:"化疗"
          },{
            icon:"fangliao",
            iconActive:"fangliaoActive",
            code:'fangliao',
            name:"放疗"
          }]
          this.adverse = this.adverseReaction.filter(item => item.type === '手术'|| item.type ==='内分泌治疗' || item.type ==='化疗' || item.type ==='放疗')
        }
        if(res.pathological_type == 'Luminal B Her2阳型' || res.pathological_type == 'Her2过表达型' ){
          this.adverseList = [{
            icon:"shoushu",
            iconActive:"shoushuActive",
            code:'shoushu',
            name:"手术"
          },{
            icon:"neifenmi",
            iconActive:"neifenmiActive",
            code:'neifenmi',
            name:"内分泌治疗"
          },{
            icon:"hualiao",
            iconActive:"hualiaoActive",
            code:'hualiao',
            name:"化疗"
          },{
            icon:"fangliao",
            iconActive:"fangliaoActive",
            code:'fangliao',
            name:"放疗"
          },{
            icon:"baxiang",
            iconActive:"baxiangActive",
            code:'baxiang',
            name:"靶向治疗"
          }]
          this.adverse = this.adverseReaction.filter(item => item.type === '手术'|| item.type ==='内分泌治疗' || item.type ==='化疗' || item.type ==='放疗' || item.type ==='靶向治疗')
        }
        if(res.pathological_type == '三阴性（-）（TNBC）'){
          this.adverseList = [{
            icon:"shoushu",
            iconActive:"shoushuActive",
            code:'shoushu',
            name:"手术"
          },{
            icon:"hualiao",
            iconActive:"hualiaoActive",
            code:'hualiao',
            name:"化疗"
          },{
            icon:"fangliao",
            iconActive:"fangliaoActive",
            code:'fangliao',
            name:"放疗"
          }]
          this.adverse = this.adverseReaction.filter(item => item.type === '手术'|| item.type ==='化疗' || item.type ==='放疗')
        }
      })
    },
    onTubercle(){
      sessionState(this.$store.getters.session).then((res)=>{
        console.log(res.symptoms)
       // let text = res.symptoms.slice('1','-1')
        let text = res.symptoms.replace(/\s*/g,"");
        text = text.slice('1','-1')
        text = text.split(',')
        let box = text
        for(let i = 0; i < box.length; i++){
          let str =  box[i].slice('1','-1')
          this.symptoms.push(str)
        }
        this.getTubercle('0',this.symptoms[0])
      })
    },
    getTubercle(index,name){
      this.tubercleShow = index
      this.tubercleData = this.tubercle.filter(item => item.type === name)
    },
    geclickNumt(code){
      this.isActive = code
      document.querySelector('#'+code).scrollIntoView()
    }

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.disease{
  width: 100%;
  height: 100%;
  background: #F3F4FC;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  .disease-top{
    background: #fff;
    margin-bottom: .4rem;
    .title{
      line-height: .64rem;
      border-bottom: 1px solid rgba(151, 151, 151, 0.1);
      padding: 0 .3rem;
      font-weight: 500;
      color: #131313;
      font-size: .28rem;
      display: flex;
      padding-top: .2rem;
      div{
        margin-right: .6rem;
      }
    }
    .active{
      color: #0068FF;
    }
    .list-box{
      padding: .3rem;
      color: #131313;
      font-size: .28rem;
    }
  }
  .tab-box{
    .tab-title{
      display: flex;
      margin: 0 .3rem .4rem .3rem;
      div{
        width: 1.46rem;
        height: .64rem;
        background: rgba(196, 196, 196, 0.5);
        border-radius: .16rem;
        color: #FFFFFF;
        line-height: .64rem;
        text-align: center;
        margin-right: .24rem;
        font-size: .24rem;
      }
      .active{
        background: linear-gradient(165deg, #55ACFF 0%, #169DFF 100%);
      }
    }
    .box{
      margin: 0 .3rem;
      .list-top{
        width: 100%;
        height: .85rem;
        background: url("../../../src/assets/images/list-top.png") no-repeat;
        background-size: 100% 100%;
        font-weight: 500;
        color: #131313;
        line-height: .85rem;
        padding-left: .3rem;
      }
      .title{
        font-weight: 500;
        color: #131313;
        margin-bottom: .4rem;
        margin-top: .6rem;
      }
      .list{
        color: #131313;
        width: 6.9rem;
        padding: .3rem;
        font-size: .28rem;
      }
    }
  }
}

.my-swipe .van-swipe-item {
  width: 100%;
  height: 3.35rem;
  img{
    width: 100%;
  }
}
.buliangfanying{
  width: 92%;
  height: 1.1rem;
  position: fixed;
  padding: 0 .44rem;
  left: .3rem;
  bottom: .3rem;
  background: rgba(184, 184, 197, 0.7);
  border-radius: .16rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: .24rem;
  .active{
    color: #0068FF;
  }
  div{
    text-align: center;
  }
}
.boxa{
  background: linear-gradient(132deg, #FDFFFF 0%, rgba(255, 233, 246, 0.84) 54%, #DDF0FF 100%);
  border-radius: .24rem 0 .24rem .24rem;
}
</style>

