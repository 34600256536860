var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "disease" }, [
    _vm.$route.query.type
      ? _c(
          "div",
          [
            _c(
              "van-swipe",
              {
                staticClass: "my-swipe",
                attrs: { autoplay: 3000, "indicator-color": "white" }
              },
              [
                _c("van-swipe-item", [
                  _c("img", {
                    attrs: {
                      src: require("../../../src/assets/images/banner01.png")
                    }
                  })
                ])
              ],
              1
            ),
            _c("div", [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "tab-box",
                  staticStyle: { "margin-bottom": ".5rem" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "tab-title" },
                    _vm._l(_vm.symptoms, function(item, index) {
                      return _c(
                        "div",
                        {
                          class: { active: _vm.tubercleShow == index },
                          on: {
                            click: function($event) {
                              return _vm.getTubercle(index, item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "box", attrs: { id: "summary" } }, [
                    _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                      _c(
                        "div",
                        { staticClass: "list-top" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "jibingmiaoshu" }
                          }),
                          _vm._v(" 疾病概述")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list" }, [
                        _vm._v(" " + _vm._s(_vm.tubercleData[0].summary) + " ")
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "box", attrs: { id: "diagnosis" } },
                    [
                      _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                        _c(
                          "div",
                          { staticClass: "list-top" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "jianchaxiangmu" }
                            }),
                            _vm._v(" 诊断方法")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "list" }, [
                          _vm._v(
                            " " + _vm._s(_vm.tubercleData[0].diagnosis) + " "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "box", attrs: { id: "followup" } }, [
                    _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                      _c(
                        "div",
                        { staticClass: "list-top" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "suifangzhiliao" }
                          }),
                          _vm._v(" 随访观察")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list" }, [
                        _vm._v(" " + _vm._s(_vm.tubercleData[0].followup) + " ")
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "box", attrs: { id: "prevention" } },
                    [
                      _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                        _c(
                          "div",
                          { staticClass: "list-top" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "jibingyufang1" }
                            }),
                            _vm._v(" 疾病预防")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "list" }, [
                          _vm._v(
                            " " + _vm._s(_vm.tubercleData[0].prevention) + " "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "box", attrs: { id: "treatment" } },
                    [
                      _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                        _c(
                          "div",
                          { staticClass: "list-top" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "yaowuzhiliao" }
                            }),
                            _vm._v(" 治疗方法")
                          ],
                          1
                        ),
                        _c("div", { staticClass: "list" }, [
                          _vm._v(
                            " " + _vm._s(_vm.tubercleData[0].treatment) + " "
                          )
                        ])
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "box", attrs: { id: "risk" } }, [
                    _c("div", { staticStyle: { "margin-top": ".3rem" } }, [
                      _c(
                        "div",
                        { staticClass: "list-top" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "jianchaxiangmu" }
                          }),
                          _vm._v(" 癌变风险")
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list" }, [
                        _vm._v(" " + _vm._s(_vm.tubercleData[0].risk) + " ")
                      ])
                    ])
                  ]),
                  _c("div", {
                    staticStyle: { width: "100%", height: "1.5rem" }
                  }),
                  _c(
                    "div",
                    { staticClass: "buliangfanying" },
                    _vm._l(_vm.jiejieList, function(item) {
                      return _c(
                        "div",
                        {
                          class: { active: _vm.isActive == item.code },
                          on: {
                            click: function($event) {
                              return _vm.geclickNumt(item.code)
                            }
                          }
                        },
                        [
                          _vm.isActive !== item.code
                            ? _c(
                                "p",
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": item.icon }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "p",
                                [
                                  _c("svg-icon", {
                                    attrs: { "icon-class": item.iconActive }
                                  })
                                ],
                                1
                              ),
                          _vm._v(" " + _vm._s(item.name) + " ")
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ])
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "van-swipe",
              {
                staticClass: "my-swipe",
                attrs: { autoplay: 3000, "indicator-color": "white" }
              },
              [
                _c("van-swipe-item", [
                  _c("img", {
                    attrs: {
                      src: require("../../../src/assets/images/banner01.png")
                    }
                  })
                ]),
                _c("van-swipe-item", [
                  _c("img", {
                    attrs: {
                      src: require("../../../src/assets/images/banner02.png")
                    }
                  })
                ]),
                _c("van-swipe-item", [
                  _c("img", {
                    attrs: {
                      src: require("../../../src/assets/images/banner03.png")
                    }
                  })
                ])
              ],
              1
            ),
            _vm.show
              ? _c("div", [
                  _c("div", { staticClass: "disease-top" }, [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "div",
                        {
                          class: { active: _vm.active == "0" },
                          on: {
                            click: function($event) {
                              _vm.active = "0"
                            }
                          }
                        },
                        [_vm._v("疾病概述")]
                      ),
                      _c(
                        "div",
                        {
                          class: { active: _vm.active == "1" },
                          on: {
                            click: function($event) {
                              _vm.active = "1"
                            }
                          }
                        },
                        [_vm._v("治疗方式")]
                      )
                    ]),
                    _c("div", { staticClass: "list-box" }, [
                      _vm.active == "0"
                        ? _c("div", [
                            _vm._v(
                              _vm._s(_vm.basic[0].value.diseaseDescription)
                            )
                          ])
                        : _vm._e(),
                      _vm.active == "1"
                        ? _c("div", [
                            _vm._v(_vm._s(_vm.basic[0].value.treatmentMode))
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "tab-box" }, [
                    _c("div", { staticClass: "tab-title" }, [
                      _c(
                        "div",
                        {
                          class: { active: _vm.boxActive == "0" },
                          on: {
                            click: function($event) {
                              _vm.boxActive = "0"
                            }
                          }
                        },
                        [_vm._v("随访方案")]
                      ),
                      _c(
                        "div",
                        {
                          class: { active: _vm.boxActive == "1" },
                          on: {
                            click: function($event) {
                              _vm.boxActive = "1"
                            }
                          }
                        },
                        [_vm._v("不良反应")]
                      ),
                      _c(
                        "div",
                        {
                          class: { active: _vm.boxActive == "2" },
                          on: {
                            click: function($event) {
                              _vm.boxActive = "2"
                            }
                          }
                        },
                        [_vm._v("伴随疾病")]
                      )
                    ]),
                    _c("div", { staticClass: "box" }, [
                      _vm.boxActive == "0"
                        ? _c(
                            "div",
                            [
                              _vm._l(_vm.followUp, function(item) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "boxa",
                                    staticStyle: { "margin-top": ".3rem" },
                                    attrs: { id: item.code }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-top" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "jianchaxiangmu"
                                          }
                                        }),
                                        _vm._v(" 检查项目")
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "list" }, [
                                      _c("div", [_vm._v(_vm._s(item.name))]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "shijianpianlv"
                                              }
                                            }),
                                            _vm._v(" 时间频率")
                                          ],
                                          1
                                        ),
                                        _vm._v(" " + _vm._s(item.time) + " ")
                                      ]),
                                      item.center != ""
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "pinggujieguo"
                                                  }
                                                }),
                                                _vm._v(" 结果评估标准")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.center) + " "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              _c("div", {
                                staticStyle: { width: "100%", height: "1.5rem" }
                              }),
                              _c(
                                "div",
                                { staticClass: "buliangfanying" },
                                _vm._l(_vm.suifangList, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        active: _vm.isActive == item.code
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.geclickNumt(item.code)
                                        }
                                      }
                                    },
                                    [
                                      _vm.isActive !== item.code
                                        ? _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.icon
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.iconActive
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.boxActive == "1"
                        ? _c(
                            "div",
                            [
                              _vm._l(_vm.adverse, function(item) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "boxa",
                                    staticStyle: { "margin-top": ".3rem" },
                                    attrs: { id: item.code }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-top" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "jibingmingcheng"
                                          }
                                        }),
                                        _vm._v(" " + _vm._s(item.name))
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "list" }, [
                                      item.center
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": ".4rem"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.center) + " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      item.prevention
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "jibingyufang"
                                                  }
                                                }),
                                                _vm._v(" 疾病预防")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(item.prevention) +
                                                " "
                                            )
                                          ])
                                        : _vm._e(),
                                      item.operation
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "life"
                                                  }
                                                }),
                                                _vm._v(" 手术治疗")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.operation) + " "
                                            )
                                          ])
                                        : _vm._e(),
                                      item.life
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "life"
                                                  }
                                                }),
                                                _vm._v(" 生活方式干预")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.life) + " "
                                            )
                                          ])
                                        : _vm._e(),
                                      item.medicine
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "yaowuzhiliao"
                                                  }
                                                }),
                                                _vm._v(" 药物治疗")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.medicine) + " "
                                            )
                                          ])
                                        : _vm._e(),
                                      item.followup
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "suifangzhiliao"
                                                  }
                                                }),
                                                _vm._v(" 随访检测")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.followup) + " "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              _c("div", {
                                staticStyle: { width: "100%", height: "1.5rem" }
                              }),
                              _c(
                                "div",
                                { staticClass: "buliangfanying" },
                                _vm._l(_vm.adverseList, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        active: _vm.isActive == item.code
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.geclickNumt(item.code)
                                        }
                                      }
                                    },
                                    [
                                      _vm.isActive !== item.code
                                        ? _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.icon
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.iconActive
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.boxActive == "2"
                        ? _c(
                            "div",
                            [
                              _vm._l(_vm.accompany, function(item) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "boxa",
                                    staticStyle: { "margin-top": ".3rem" },
                                    attrs: { id: item.code }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-top" },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class": "jibingmingcheng"
                                          }
                                        }),
                                        _vm._v(" 疾病名称")
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "list" }, [
                                      _c("div", [_vm._v(_vm._s(item.name))]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "jibingyufang"
                                              }
                                            }),
                                            _vm._v(" 疾病预防")
                                          ],
                                          1
                                        ),
                                        _vm._v(" " + _vm._s(item.center) + " ")
                                      ]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _c("svg-icon", {
                                              attrs: { "icon-class": "life" }
                                            }),
                                            _vm._v(" 生活方式干预")
                                          ],
                                          1
                                        ),
                                        _vm._v(" " + _vm._s(item.life) + " ")
                                      ]),
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "title" },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class": "yaowuzhiliao"
                                              }
                                            }),
                                            _vm._v(" 药物治疗")
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          " " + _vm._s(item.medicine) + " "
                                        )
                                      ]),
                                      item.followup != ""
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class":
                                                      "suifangzhiliao"
                                                  }
                                                }),
                                                _vm._v(" 随访检测")
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(item.followup) + " "
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              _c("div", {
                                staticStyle: { width: "100%", height: "1.5rem" }
                              }),
                              _c(
                                "div",
                                { staticClass: "buliangfanying" },
                                _vm._l(_vm.jibingList, function(item) {
                                  return _c(
                                    "div",
                                    {
                                      class: {
                                        active: _vm.isActive == item.code
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.geclickNumt(item.code)
                                        }
                                      }
                                    },
                                    [
                                      _vm.isActive !== item.code
                                        ? _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.icon
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "p",
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": item.iconActive
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ]
                                  )
                                }),
                                0
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "disease-top" }, [
      _c("div", { staticClass: "title" }, [_c("div", [_vm._v("乳腺自查")])]),
      _c("div", { staticClass: "list-box" }, [
        _vm._v(
          " 育龄期妇女应学会自查乳腺。（1）时间：每次月经后5~10天为宜。（2）体位：首先端坐、两臂自然下垂，乳房肥大下垂明显者，可平卧，肩下垫小枕，使胸部隆起。左手检查右侧乳房，右手检查左侧乳房，不能同时检查两侧。若只有一侧有肿块，先查健侧再查患侧。 检查顺序：以乳头为中心，把乳房分成四个象限。应从乳房的外上(包括腋窝部)、外下、内下、内上各象限及中央区依次全面检查。 注意事项：应用手指掌面而不是指尖，不要用手指捏乳房组织，否则会将捏到的乳腺组织误认为是肿块。 "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }